import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'

Vue.config.productionTip = false

// Desarrollo
// axios.defaults.baseURL = 'http://localhost:4000'

// Desarrollo para red local
// axios.defaults.baseURL = 'http://10.16.0.57:4000'

// Producción  sin SSL
// axios.defaults.baseURL = 'http://wscv.ine.gob.bo:4000'

// Producción con SSL   (Quitar el :4000)
axios.defaults.baseURL = 'https://wscv.ine.gob.bo'

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')