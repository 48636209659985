<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    selectedItem: 1,
    items: [
      { text: "Mi cuenta", icon: "mdi-account" },
      { text: "Configuraciones", icon: "mdi-cog" },
      {
        text: "Cerrar sesion",
        icon: "mdi-close-circle-outline",
        color: "error",
      },
    ],

    vehiculo: [
      ["Dashboard", "mdi-monitor-dashboard", "/dashboard_vehiculo"],
      ["Registro", "mdi-car-outline", "/registro"],
      ["SOAT", "mdi-alpha-s-box-outline", "/soat"],
      ["Seguro", "mdi-car-emergency", "/seguro"],
      ["Inspección técnica v", "mdi-car-search", "/itv"],
      ["Impuestos", "mdi-alpha-i-circle-outline", "/impuestos"],
      ["Asignacion", "mdi-car-arrow-right", "/asignacion"],
      ["Ubicación", "mdi-car-select", "/ubicacion"],
      /* ["Reportes", "mdi-database-search-outline", "/reporte"], */
    ],
    usuario: [["Registro", "mdi-table", "/registro_usuario"]],
    mantenimiento: [["Registro", "mdi-car-cog", "/registro_mantenimiento"]],
  }),
};
</script>


