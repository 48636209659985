import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  /* USUARIOS */
  {
    path: '/usuarios',
    name: 'scv1',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'usuario_opciones',
        component: () => import('../views/usuarios/Usuario_opciones.vue')
      },
      {
        path: '/registro_usuario',
        name: 'registro_usuario',
        component: () => import('../views/usuarios/Registro.vue')
      },
      {
        path: '/mi_cuenta',
        name: 'mi_cuenta',
        component: () => import('../views/usuarios/MiCuenta.vue')
      },
      {
        path: '/roles_permisos',
        name: 'roles_permisos',
        component: () => import('../views/usuarios/RolesPermisos.vue')
      },
    ]
  },
  /* ASIGNACIONES */
  {
    path: '/asignaciones',
    name: 'scv2',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'asignacion_opciones',
        component: () => import('../views/asignaciones/Asignacion_opciones.vue')
      },
      {
        path: '/asignaciones/transferencia_vehiculo',
        name: 'transferencia',
        component: () => import('../views/asignaciones/Transferencia_vehiculo.vue')
      },
      {
        path: '/asignaciones/registro_chofer',
        name: 'asignacion_registro_chofer',
        component: () => import('../views/asignaciones/Registro_chofer.vue')
      },
      {
        path: '/asignaciones/asignacion_chofer',
        name: 'asignacion_chofer',
        component: () => import('../views/asignaciones/Asignacion_chofer.vue')
      },
      {
        path: '/asignaciones/asignacion_accesorios_herramientas',
        name: 'Asignacion_accesorios_herramientas',
        component: () => import('../views/asignaciones/Asignacion_accesorios_herramientas.vue')
      },
    ]
  },
  /* REPORTES */
  {
    path: '/reportes',
    name: 'scv3',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'reporte_opciones',
        component: () => import('../views/reportes/Reporte_opciones.vue')
      },
      {
        path: '/reportes/buscar_vehiculos',
        name: 'reportes_buscar_vehiculos',
        component: () => import('../views/reportes/Buscar_vehiculos.vue')
      },
      {
        path: '/reportes/resumen_consolidado_vehiculos',
        name: 'reportes_resumen_consolidado_vehiculos',
        component: () => import('../views/reportes/Consolidado.vue')
      },
      {
        path: '/reportes/reporte_soat_itv_sv_iv',
        name: 'reportes_Reporte_soat_itv_sv_iv',
        component: () => import('../views/reportes/Reporte_soat_itv_sv_iv.vue')
      }      
    ]
  },

  /* BITACORA */
  {
    path: '/bitacora',
    name: 'scv4',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'bitacora_opciones',
        component: () => import('../views/bitacora/Bitacora_opciones.vue')
      },
      /* BITACORA */
      {
        path: '/bitacora/admin',
        name: 'bitacora_admin',
        component: () => import('../views/bitacora/bitacora.vue')
      },
    ]
  },


  /* CONFIGURACIONES */
  {
    path: '/configuraciones',
    name: 'scv5',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'configuracion_opciones',
        component: () => import('../views/configuraciones/Configuracion_opciones.vue')
      },
      {
        path: '/configuraciones/opciones_vehiculo',
        name: 'opciones_vehiculo',
        component: () => import('../views/configuraciones/Opciones_vehiculo.vue')
      },
      {
        path: '/configuraciones/opciones_herramienta',
        name: 'opciones_herramienta',
        component: () => import('../views/configuraciones/Opciones_herramienta.vue')
      },
      {
        path: '/configuraciones/opciones_mantenimiento',
        name: 'opciones_herramienta',
        component: () => import('../views/configuraciones/Opciones_mantenimiento.vue')
      }

    ]
  },

  /* MANTENIMIENTO */
  {
    path: '/mantenimiento',
    name: 'scv6',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'mantenimiento_opciones',
        component: () => import('../views/mantenimiento/Mantenimiento_opciones.vue')
      },
      {
        path: '/mantenimiento/reporte_fallas',
        name: 'reporte_fallas',
        component: () => import('../views/mantenimiento/Reporte_fallas.vue')
      },
      {
        path: '/mantenimiento/presupuesto',
        name: 'presupuesto',
        component: () => import('../views/mantenimiento/Presupuesto.vue')
      },
      {
        path: '/mantenimiento/vehiculo',
        name: 'mantenimiento_vehiculo',
        component: () => import('../views/mantenimiento/Registro_mantenimiento.vue')
      }
    ]
  },


  /* VEHICULO */
  {
    path: '/vehiculo',
    name: 'scv7',
    component: () => import('../views/Scv.vue'), children: [
      {
        path: '',
        name: 'vehiculo_opciones',
        component: () => import('../views/vehiculos/Vehiculo_opciones.vue')
      },
      {
        path: '/vehiculo/registro_vehiculo',
        name: 'registro',
        component: () => import('../views/vehiculos/Registro.vue')
      },
      {
        path: '/vehiculo/comodato',
        name: 'registro_comodato',
        component: () => import('../views/vehiculos/Comodato.vue')
      },
      {
        path: '/vehiculo/formulario_registro',
        name: 'vehiculo_adicionar',
        component: () => import('../views/vehiculos/Adicionar.vue')
      },
      {
        path: '/vehiculo/editar',
        name: 'vehiculo_editar',
        component: () => import('../views/vehiculos/Editar.vue')
      },
      {
        path: '/vehiculo/soat',
        name: 'soat',
        component: () => import('../views/vehiculos/Soat.vue')
      },
      {
        path: '/vehiculo/seguro',
        name: 'seguro',
        component: () => import('../views/vehiculos/Seguro.vue')
      },
      {
        path: '/vehiculo/itv',
        name: 'itv',
        component: () => import('../views/vehiculos/Itv.vue')
      },
      {
        path: '/vehiculo/impuestos',
        name: 'impuestos',
        component: () => import('../views/vehiculos/Impuestos.vue')
      },
      /*  */

      {
        path: '/reporte',
        name: 'reporte',
        component: () => import('../views/vehiculos/Reporte.vue')
      },
      {
        path: '/dashboard_vehiculo',
        name: 'dashboard_vehiculo',
        component: () => import('../views/vehiculos/Dashboard_vehiculo.vue')
      },
      {
        path: '/ubicacion',
        name: 'ubicacion',
        component: () => import('../views/vehiculos/Ubicacion.vue')
      },
    ]
    
  },
  {
    path: '/acceso_denegado',
    component: () => import('../views/acceso_denegado/acceso_denegado.vue')
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('../views/error404/error404.vue')
  }
]

const router = new VueRouter({
  mode: '',
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
