<template>
  <v-container fluid>
    <!--  -->
    <v-dialog v-model="dialogCarousel" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Fotos del vehículo
        </v-card-title>

        <v-carousel
          cycle
          height="500"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet :color="colors[i]" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-h2">{{ slide }} Slide</div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="primary" @click="dialogCarousel = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog SOAT -->
    <v-dialog v-model="dialogSoat" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> SOAT </v-card-title>

        <v-card-text>
          <v-card> datos del vehiculo </v-card>
          <br />
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="date"
                        label="Fecha expedición"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                  outlined
                    v-model="firstname"                    
                    label="Monto (Bs.)"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions> <!-- Soat -->
          <v-spacer></v-spacer>
          <v-btn @click="dialogSoat = false" elevation="0"> Cancelar </v-btn>

          <v-btn color="primary" elevation="0" @click="dialogSoat = false">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGO DETALLE -->
    <v-dialog v-model="dialogInfoVehiculo" width="60%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Información del vehículo
        </v-card-title>

        <v-card-text>
          <br />
          <v-row>
            <v-col cols="4">
              <v-card outlined>
                <v-card-text>
                  <p class="primary--text">DATOS DE IDENTIFICACIÓN</p>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.placa
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >PLACA</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.clase
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >CLASE</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.marca
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >MARCA</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.tipo
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >TIPO</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.modelo
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >MODELO</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.nro_motor
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >NRO. MOTOR</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.nro_chasis
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >NRO. CHASIS</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card outlined>
                <v-card-text>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.servicio
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >SERVICIO</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <p class="primary--text">DATOS TÉCNICOS</p>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.cilindrada
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >CILINDRADA</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.traccion
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >TRACCIÓN</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.nro_ruedas
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >NRO. RUEDAS</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.nro_puertas
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >NRO. PUERTAS</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.capacidad
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >CAPACIDAD</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.nro_plazas
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >NRO. DE PLAZAS</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card> </v-col
            ><v-col cols="4">
              <v-card outlined>
                <v-card-text>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.combustible
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >COMBUSTIBLE</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>

                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.tipo_motor
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >TIPO DE MOTOR</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.peso
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >PESO</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>
                  <!--  -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        editedItem.estado
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="error--text"
                        >ESTADO</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="0"
            @click="dialogInfoVehiculo = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card outlined class="mb-4" color="grey lighten-3">
      <v-card-text>
        <h2><v-icon class="">mdi-table</v-icon> Registro de activos</h2>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="vehiculos"
      sort-by="calories"
      class="elevation-2"
      :search="search"
      :items-per-page="5"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <!-- Buscador -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
                @click="initialize"
              >
                <v-icon small class="mr-2">mdi-autorenew</v-icon> Actualizar
              </v-btn>
            </template>
            <span>Actualizar tabla</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="90%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                elevation="0"
              >
                <v-icon small class="mr-2">mdi-plus</v-icon> REGISTRO
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                {{ formTitle }}
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="4">
                        <!-- Datos de identificación -->
                        <v-card outlined>
                          <v-card-title>
                            <strong class="primary--text text-subtitle-1"
                              >Datos de identificación:</strong
                            >
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.placa"
                                  :rules="placaRules"
                                  label="Placa"
                                  dense
                                  outlined
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <br />
                                <p class="mt-n4 warning--text">Ej. 1234-ABC</p>
                              </v-col>
                            </v-row>

                            <v-row class="mt-n6">
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.clase"
                                  :items="itemClase"
                                  :rules="claseRules"
                                  dense
                                  filled
                                  label="Clase"
                                  outlined
                                  required
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.modelo"
                                  :rules="nameRules"
                                  label="Modelo"
                                  type="number"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mt-n6">
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.marca"
                                  :items="itemsMarca"
                                  dense
                                  filled
                                  label="Marca"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.nro_motor"
                                  :rules="nameRules"
                                  label="Nro. motor"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mt-n6">
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.tipo"
                                  :items="itemsTipo"
                                  dense
                                  filled
                                  label="Tipo"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.nro_chasis"
                                  :rules="nameRules"
                                  label="Nro. chasis"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mt-n6">
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.servicio"
                                  :items="itemsServicio"
                                  dense
                                  filled
                                  label="Servicio"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="6"> </v-col>
                            </v-row>

                            <v-alert type="error">
                              La placa ingresada ya fue registrada.
                            </v-alert>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="4">
                        <!-- Datos técnicos -->
                        <v-card outlined>
                          <v-card-title>
                            <strong class="primary--text text-subtitle-1"
                              >Datos técnicos:</strong
                            >
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.cilindrada"
                                  :rules="emailRules"
                                  label="Cilindrada"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.traccion"
                                  :items="itemTraccion"
                                  dense
                                  filled
                                  label="Tracción"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row class="mt-n8">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.nro_ruedas"
                                  :rules="emailRules"
                                  label="Nro de ruedas"
                                  outlined
                                  dense
                                  type="number"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.nro_puertas"
                                  :rules="emailRules"
                                  label="Nro de puertas"
                                  type="number"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="mt-n8">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.capacidad"
                                  :rules="emailRules"
                                  label="Capacidad (Tn)"
                                  type="number"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.color"
                                  :items="itemsColor"
                                  dense
                                  filled
                                  label="Color"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row class="mt-n8">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.nro_plazas"
                                  :rules="emailRules"
                                  label="Nro. de plazas"
                                  type="number"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.combustible"
                                  :items="itemsCombustible"
                                  dense
                                  filled
                                  label="Combustible"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                            </v-row>

                            <v-row class="mt-n8">
                              <v-col cols="12" md="6"
                                ><v-autocomplete
                                  v-model="editedItem.tipo_motor"
                                  :items="itemMotor"
                                  dense
                                  filled
                                  label="Tipo de motor"
                                  outlined
                                ></v-autocomplete
                              ></v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.peso"
                                  :rules="emailPeso"
                                  label="Peso Kg"
                                  type="number"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mt-n8">
                              <v-col cols="12" md="6"
                                ><v-autocomplete
                                  v-model="editedItem.procedencia"
                                  :items="itemProcedencia"
                                  dense
                                  filled
                                  label="Procedencia"
                                  outlined
                                ></v-autocomplete
                              ></v-col>
                              <v-col cols="12" md="6">
                                <v-autocomplete
                                  v-model="editedItem.estado"
                                  :items="itemsEstado"
                                  dense
                                  filled
                                  label="ESTADO"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-card outlined>
                          <v-card-title>
                            <strong class="primary--text text-subtitle-1"
                              >Propietario</strong
                            >
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-autocomplete
                                  v-model="editedItem.propietario"
                                  :items="itemsPropietario"
                                  dense
                                  filled
                                  label="Propietario"
                                  outlined
                                ></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="8">
                                <v-textarea
                                  v-model="editedItem.entidad_comodante"
                                  rows="1"
                                  outlined
                                  name="input-7-4"
                                  label="Entidad Comodante"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <br />

                        <v-card outlined>
                          <v-card-title>
                            <strong class="primary--text text-subtitle-1"
                              >Observaciones</strong
                            >
                          </v-card-title>
                          <v-card-text>
                            <v-textarea
                              v-model="editedItem.observaciones"
                              rows="2"
                              outlined
                              name="input-7-4"
                              label="Obs."
                            ></v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="1" class="mr-2" @click="close">
                  Cancelar
                </v-btn>
                <v-btn elevation="1" color="primary" large @click="save">
                  <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.placa="{ item }">
        <v-card outlined color="transparent">
          <v-list-item three-line>
            <v-card class="mr-4" max-width="100">
              <v-img
                src="https://production.autoforce.com/uploads/version/profile_image/8659/comprar-srx-4x4-automatico_a0d05b40c8.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="100px"
              >
                <v-card-title>
                  <v-btn
                    class="white--text ml-10 mb-n2"
                    icon
                    @click="verCarousel(item)"
                  >
                    + 4</v-btn
                  >
                </v-card-title>
              </v-img>
            </v-card>

            <!-- VEHICULO -->
            <v-list-item-content>
              <div class="text-overline">
                {{ item.clase }} | {{ item.tipo }}
              </div>
              <v-list-item-title class="text-body-1 primary--text">
                PLACA:
                <strong class="text-subtitle-1 black--text">
                  <v-chip outlined label class="ma-2">
                    {{ item.placa }}
                  </v-chip>
                </strong>
              </v-list-item-title>
              <v-list-item-subtitle>
                <strong class="red--text text--lighten-1">Marca:</strong>
                {{ item.marca }}
                <strong class="red--text text--lighten-1 ml-4">Modelo:</strong>
                {{ item.modelo }}
                <strong class="red--text text--lighten-1 ml-4">Color:</strong>
                {{ item.color }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>

      <!-- SOAT -->
      <template v-slot:item.soat="{ item }">
        <v-btn small elevation="0" outlined
          ><v-icon small class="mr-2">mdi-eye</v-icon> SI</v-btn
        >
      </template>
      <!-- ITV -->
      <template v-slot:item.itv="{ item }">
        <v-btn small elevation="0" outlined
          ><v-icon small class="mr-2">mdi-eye</v-icon>NO</v-btn
        >
      </template>

      <!-- SEGURO AUT. -->
      <template v-slot:item.sa="{ item }">
        <v-btn small elevation="0" outlined
          ><v-icon small class="mr-2">mdi-eye</v-icon>NO</v-btn
        >
      </template>

      <!-- DETALLE -->
      <template v-slot:item.detalle="{ item }">
        <v-btn small elevation="0" outlined @click="verDetalleVehiculo(item)"
          ><v-icon>mdi-list-box-outline</v-icon></v-btn
        >
      </template>

      <!-- ESTADO -->
      <template v-slot:item.estado="{ item }">
        <v-chip
          small
          v-if="item.estado == 'OPERABLE'"
          class="ma-2"
          color="success"
        >
          {{ item.estado }}
        </v-chip>
        <v-chip small v-else class="ma-2" color="error">
          {{ item.estado }}
        </v-chip>
      </template>

      <!-- ACCIONES -->
      <template v-slot:item.actions="{ item }">
        <!-- Actualizar -->
        <v-icon color="primary" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <!-- Eliminar -->
        <v-icon class="mr-2" color="primary" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>

      <!-- RESET -->
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    /* Reglas de validacion */
    valid: true,
    placaRules: [
      (v) => !!v || "El campo es requerido",
      (v) =>
        (v && v.length <= 10) || "El campo no debe tener más de 10 caracteres",
    ],

    claseRules: [(v) => !!v || "Selección requerida"],

    /* Img */
    colors: ["error", "error", "error", "error", "error"],
    slides: ["Frente", "Atras", "Izquierdo", "Derecho", "Interior"],
    search: "",

    /* servicio */
    itemServicio: ["OFICIAL", "PARTICULAR"],

    /*  */
    itemTraccion: ["SIMPLE", "DOBLE"],

    /* Tipo de Motor */
    itemMotor: ["INYECCION", "CARBURADOR"],

    /*  */
    itemProcedencia: [
      "MEXICO",
      "JAPON",
      "INDONESIA",
      "CHINA",
      "ESTADOS UNIDOS",
      "INDIA",
      "COREA DEL SUR",
      "ALEMANIA",
      "BRASIL",
      "ESPAÑA",
      "TAILANDIA",
      "FRANCIA",
      "CANADA",
      "ITALIA",
      "RUSIA",
      "ARGENTINA",
      "REINO UNIDO",
    ],
    /* clase */
    itemClase: [
      "CAMIONETA",
      "AUTOMOVIL",
      "JEEP",
      "VAGONETA",
      "MINIBUS 8 OCUPANTES",
      "MOTOCICLETA",
      "FRONTIER",
      "FURGONETA",
      "CAMIONETA 4X4",
      "CAMIONETA 4X2",
      "BUS",
      "MINIBUS",
      "SEDAN",
      "RURAL",
      "FURGON",
      "CAMION",
      "REMOLQUE",
      "COLECTIVO",
      "MICRO",
      "HATCHBACK",
      "PICKUP",
      "VAN",
      "MINIVAN",
    ],
    /* tipo de vehículo */
    itemsTipo: [
      "FRONTIER",
      "HILUX",
      "BALENO",
      "GRAND VITARA 3P",
      "CARRY VAN",
      "APV",
      "LAND CRUISER",
      "DR200SEY",
      "HILUX SURF",
      "GRANVIA",
      "GAIA",
      "REGIUS",
    ],
    itemsMarca: [
      "NISSAN",
      "TOYOTA",
      "SUZUKI",
      "RENAULT",
      "CHEVROLET",
      "FORD",
      "AUDI",
      "BMW",
      "DACIA",
      "FERRARI",
      "FIAT",
      "HONDA",
      "HYUNDAI",
      "ISUZU",
      "JAGUAR",
      "JEEP",
      "KIA",
      "LAMBORGHINI",
      "VOLVO",
      "IVECO",
      "LAND ROVER",
      "MAZDA",
      "MERCEDES",
      "MITSUBISHI",
      "NISSAN",
      "PEUGEOT",
      "RENAULT",
      "ROLL ROYCE",
      "SUBARU",
      "TESLA",
      "VOLKSWAGEN",
      "CHANGAN",
      "GAC",
      "HAVAL",
      "JAC",
      "KING LONG",
    ],
    itemsColor: [
      "ROJO",
      "NEGRO",
      "BLANCO",
      "GRIS",
      "PLATA",
      "AZUL",
      "VERDE",
      "AMARILLO",
    ],
    itemsCombustible: ["GASOLINA", "DIESEL", "GLP", "ELECTRICO", "HIBRIDO"],
    itemsServicio: ["OFICIAL", "PARTICULAR"],
    itemsEstado: ["OPERABLE", "NO OPERABLE"],
    itemsPropietario: ["INE", "COMODATO"],

    dialog: false,

    /* Dialogo Info. vehículo */
    dialogInfoVehiculo: false,

    /* Dialogo SOAT */
    dialogSoat: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu2: false,

    /* Dialog Carousel */
    dialogCarousel: false,
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],

    dialogDelete: false,
    headers: [
      {
        text: "VEHÍCULO",
        align: "start",
        sortable: false,
        value: "placa",
        class: "black--text text-button font-weight-bold",
      },
      /* { text: "CLASE", value: "clase", class: "primary--text" },
      { text: "MARCA", value: "marca", class: "primary--text" },
      { text: "MODELO", value: "modelo", class: "primary--text" },
      { text: "COLOR", value: "color", class: "primary--text" }, */
      /* {
        text: "DIMENSIONES (mts)",
        value: "dimensiones",
        class: "primary--text",
      }, */
      {
        text: "SERVICIO",
        value: "servicio",
        class: "primary--text",
        sortable: false,
        class: "black--text text-button font-weight-bold",
      },
      {
        text: "PROPIETARIO",
        align: "center",
        value: "propietario",
        class: "primary--text",
        sortable: false,
        class: "black--text text-button font-weight-bold",
      },
      {
        text: "ESTADO",
        align: "center",
        value: "estado",
        sortable: false,
        class: "primary--text",
        class: "black--text text-button font-weight-bold",
      },
      {
        text: "SOAT",
        value: "soat",
        align: "center",
        sortable: false,
        class: "black--text text-button font-weight-bold",
      },
      {
        text: "ITV",
        value: "itv",
        align: "center",
        sortable: false,
        class: "black--text text-button font-weight-bold",
      },
      {
        text: "SEGURO",
        value: "sa",
        align: "center",
        sortable: false,
        class: "black--text text-button font-weight-bold",
      },
      {
        text: "DETALLE",
        value: "detalle",
        align: "center",
        sortable: false,
        class: "black--text text-button font-weight-bold",
      },

      /* { text: "CHOFER ASIGNADO", value: "chofer", class: "primary--text" }, */
      {
        text: "ACCIONES",
        value: "actions",
        sortable: false,
        width: "200",
        align: "center",
        class: "primary--text text-button font-weight-bold",
      },
    ],
    vehiculos: [],
    editedIndex: -1,
    editedItem: {
      placa: null,
      clase: null,
      marca: null,
      tipo: null,
      modelo: null,
      nro_motor: null,
      nro_chasis: null,
      servicio: null,
      cilindrada: null,
      traccion: null,
      nro_ruedas: null,
      nro_puertas: null,
      capacidad: null,
      color: null,
      nro_plazas: null,
      combustible: null,
      tipo_motor: null,
      peso: null,
      estado: null,
      procedencia: null,
      propietario: null,
      observaciones: null,
      entidad_comodante: null,
    },
    defaultItem: {
      placa: null,
      clase: null,
      marca: null,
      tipo: null,
      modelo: null,
      nro_motor: null,
      nro_chasis: null,
      servicio: null,
      cilindrada: null,
      traccion: null,
      nro_ruedas: null,
      nro_puertas: null,
      capacidad: null,
      color: null,
      nro_plazas: null,
      combustible: null,
      tipo_motor: null,
      peso: null,
      estado: null,
      procedencia: null,
      propietario: null,
      observaciones: null,
      entidad_comodante: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const res = await axios.post("/ListarVehiculo");
      this.vehiculos = res.data;
    },

    verDetalleVehiculo(item) {
      this.dialogInfoVehiculo = true;
      this.editedIndex = this.vehiculos.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    verCarousel(item) {
      this.dialogCarousel = true;
      this.editedIndex = this.vehiculos.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    editItem(item) {
      this.editedIndex = this.vehiculos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.vehiculos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.vehiculos.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.vehiculos[this.editedIndex], this.editedItem);
      } else {
        /* Adicionar */
        /* Adición */
        await axios.post("/AdicionarVehiculo", this.editedItem);
        this.initialize();
        //this.vehiculos.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>